/* navbar section */
header {
  border-bottom: 1px solid #0c4d2927;
}
.header-text {
  text-align: center;
  background-color: #0c4d29;
  padding: 11px;
}
.header-text a {
  font-size: 13px;
  text-decoration: none;
  letter-spacing: 0.2px;
}

.navibar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 94px;
  background-color: white;
  position: relative;
}

/* fixed navbar */
.fixedNavbar {
  position: fixed !important;
  top: 0;
  width: 100%;
  right: 0;
  left: 0;
  z-index: 10;
}
.menuBtn {
  display: none;
}
.left-nav {
  display: flex;
  align-items: center;
  height: 100%;
}

.navlinks {
  list-style: none;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  height: 100%;
}
.navlinks > li {
  padding: 0px 18px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.navlinks > li a {
  color: #0c4d29;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400 !important;
}

.navlinks > li:hover .dropdown-links {
  display: block;
}

.mobile-navlinks {
  display: none;
}

/* inner links */
.dropdown-links {
  list-style: none;
  padding-left: 0;
  position: absolute;
  top: 100%;
  padding: 10px 20px;
  min-width: 250px;
  border: 1px solid #2222224d;
  border-top: 2px solid #0c4d29;
  box-shadow: 1px 1px 20px #22222218;
  background-color: white;
  z-index: 2;
  display: none;
}

.dropdown-links li {
  padding: 5px;
  color: #0c4d29;
  cursor: pointer;
}

/* nav icons */
.nav-icon {
  font-size: 23px;
  margin-left: 20px;
  cursor: pointer;
  color: #0c4d29;
}

/* search bar */
.search-bar {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  right: 0;
  height: 0px;
  z-index: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 20px #2222223a;
  transition: all 0.2s;
}

.search-height {
  height: 80px;
}

.search-field {
  width: 100%;
  padding: 10px 10px 10px 55px;
  font-size: 19px;
  outline: none;
  border: none;
}

#search-field-icon {
  position: absolute;
  top: 26%;
  left: -1.5%;
  color: #787878;
}

/* footer css */
footer {
  border-top: 1px solid #0c4d2927;
}
footer h5 {
  margin-bottom: 20px;
  font-size: 16px;
  color: #5c5c5c;
  font-weight: 400;
}
.footer-first p {
  font-size: 16px;
  color: #939393;
  letter-spacing: 0.6px;
}

.social-icon {
  font-size: 18px;
  margin-right: 30px;
  margin-top: 20px;
  color: #939393;
}

.footer-links a {
  display: block;
  padding: 0 6px 12px;
  text-decoration: none;
  font-size: 16px;
  color: #939393;
}

.footer-links a:hover {
  color: #444;
}

.subscribe p {
  color: #939393;
  font-size: 16px;
  letter-spacing: 0.6px;
}
.subscribe input {
  width: 100%;
  padding: 9px 10px;
  border: 1px solid #e3e3e3;
  color: #787878;
}
.select-field1 {
  width: 130px;
}
.select-field2 {
  width: 190px;
}
.subs-btn {
  padding: 10px 40px;
  border: none;
  background-color: #0c4d29;
  color: white;
  letter-spacing: 2px;
  font-size: 15px;
}

/* yurf banner */
/* .yurf-banner{
    background-image: linear-gradient(rgb(12, 77, 41, .35), rgb(12, 77, 41, .35)), url('https://nuturf.com.au/images/2022/05/18/rolls-of-newly-installed-grass-turfs-next-to-pool--8ep8atx.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.top-main-heading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.select-field {
  width: 195px;
}

.banner-content {
  width: 90%;
  text-align: center;
  margin: auto;
}
.banner-content p {
  font-size: 20px;
  letter-spacing: 0.6px;
  font-weight: 600;
}
.yurf-card {
  background-color: #197a45;
  padding: 20px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.95;
  transition: all 0.3s;
}
.yurf-card:hover {
  background-color: #093a1f;
}
/* Turf Categories Component */
.turf-card {
  margin-top: 40px;
  overflow: hidden;
  box-shadow: 1px 1px 20px #1111112a;
}
.turf-card > img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  transition: all 0.3s;
}

.turf-card > img:hover {
  transform: scale(1.05);
}

.turf-details {
  background-color: white;
  padding: 15px;
}

.turf-details h6 {
  color: #197a45;
}

/* for modal css */
.modal-content {
  border-radius: 0px !important;
  padding: 10px;
}
.modal-backdrop.show {
  opacity: 0.78;
  background-color: #0c4d29e5;
  /* background-image: linear-gradient(#979936de, #0c4d29e5)  ; */
}

/* cart page */
.table-box {
  width: 80%;
  margin: 0 auto;
}
.cart-bottom-details {
  width: 80%;
  margin: 0 auto;
}
.cart-table td {
  padding: 25px 0px;
}
.cart-table th {
  color: #787878;
  font-weight: 550;
  font-size: 16px;
}
.cart-table td h6 {
  color: #333;
  font-weight: 550;
  font-size: 18px;
}
.cart-table td p {
  color: #787878;
  font-size: 16px;
  font-weight: 500;
}
.quan-counter {
  width: 120px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  border: 1px solid #0c4d29;
}
.quan-counter > div:first-child,
.quan-counter > div:last-child {
  background-color: #0c4d29;
  color: white;
  width: 35%;
}
.quan-counter > div {
  width: 40%;
  color: #787878;
  font-size: 17px;
  cursor: pointer;
  padding: 5px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.font-18 {
  font-size: 18px;
  color: #2890ff;
  text-decoration: underline;
}

.bd-header-modal {
  display: flex;
  padding: 10px 25px;
  justify-content: space-between;
  align-items: center;
}

.btn-checkout {
  padding: 12px 15px;
  background-color: #197a45;
  border: none;
  color: white;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
  line-height: 0;
  font-size: 14px;
}

/* Responsive style ------------------------------------------ */

@media screen and (max-width: 1350px) {
  .navlinks > li {
    padding: 0 10px;
  }
  .nav-icon {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1078px) {
  .navlinks {
    display: none;
  }
  .nav-icon {
    margin-left: 25px !important;
  }
  .menuBtn {
    display: inline-block;
  }
  .user-icon {
    display: none;
  }
  .mobile-navlinks {
    display: block;
    position: fixed;
    width: 45%;
    height: 100%;
    top: 0;
    left: -100%;
    background-color: white;
    padding: 20px;
    list-style: none;
    box-shadow: 1px 1px 30px #3a393998;
    z-index: 6;
    transition: all 0.6s ease-in-out;
    overflow-y: auto;
  }
  .mobile-navlinks::-webkit-scrollbar {
    display: none;
  }
  .showMenu {
    left: 0 !important;
  }
  .cross-btn {
    font-size: 26px;
  }
  .mobile-innerLinks {
    list-style: none;
    padding-left: 0;
    height: 0;
    overflow: hidden;
    background-color: #093a1f08;
  }
  .show-inner-links {
    height: auto;
    margin-top: 20px;
  }
  .mobile-innerLinks > li {
    padding: 10px;
  }
  .mobile-navlinks > li {
    padding: 20px 1px;
    font-size: 17px;
    border-bottom: 1px solid #78787856;
  }
  .mobile-navlinks > li a {
    color: #5c5c5c;
    text-decoration: none;
  }
  /* .mobile-navlinks > li > a:hover .dropdown-links{
        display: block !important;
        z-index: 199 !important;
    } */

  /* turf detail page */
  .pdf-btn {
    /* width: 100% !important; */
    font-size: 15px !important;
    padding: 10px 15px !important;
  }
}

@media screen and (max-width: 970px) {
  .select-field {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .nav-icon {
    font-size: 20px;
  }
  #search-field-icon {
    left: -2%;
    top: 34%;
  }
  .search-height {
    height: 60px;
  }

  /* detail page */
  .share-media-btn {
    margin-left: 0 !important;
    margin-right: 20px;
  }
  .main-img img {
    height: 280px !important;
  }
  .img-options div {
    height: 130px !important;
  }
  .img-options div img {
    height: 100% !important;
  }

  /* cart table */
  .table-box {
    width: 90%;
    overflow-x: auto;
  }
  .cart-table {
    width: 700px;
  }
}

@media screen and (max-width: 550px) {
  .header-text a {
    font-size: 11px;
  }
  .search-height {
    height: 65px;
  }
  .mobile-navlinks {
    width: 85% !important;
  }
  .h5 {
    line-height: 30px;
    font-size: 18px !important;
  }
  .paragraph {
    font-size: 16px !important;
    line-height: 26px !important;
  }
  .modal-content {
    padding: 0;
  }
  .banner-content {
    width: 100%;
  }

  .select-field {
    width: 100% !important;
  }
}

@media screen and (min-width: 1900px) {
  .main-top-heading{
    font-size: 20px !important;
  }
}

.active-ingredient table tbody tr td:last-child{
  width: 100px !important;
}