.share-media-btn {
  width: 40px;
  height: 40px;
  background-color: #777;
  color: white;
  margin-left: 20px;
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 20px;
}

.share-media-btn:hover {
  background-color: #197a45;
  border-radius: 10px;
}

.pdf-btn {
  padding: 10px 30px;
  background-color: #197a45;
  color: white;
  font-size: 19px;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.main-img img {
  width: 100%;
  /* height: 415px; */
  object-fit: cover;
}

.img-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.img-options div img {
  width: 100%;
  height: 200px;
  cursor: pointer;
  /* object-fit: cover;  */
}
/* @media screen and (min-width: 1480px) {
  .main-img img {
    height: 600px;
  }
} */
.h-190 {
  height: 190px;
}
p,
h4 {
  color: var(--bs-body-color) !important;
}

/* product-card */
.product-card {
  background-color: white;
  padding: 26px 20px;
  text-align: center;
  box-shadow: 1px 1px 20px #1111112a;
}
.product-card > img {
  width: 55%;
  object-fit: contain;
  height: 250px;
}
.cursor-pointer {
  cursor: pointer;
}
/* image viewer popup */
.image-viewer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #0c4d29d2;
  z-index: 100;
  top: -100%;
  left: 0;
  transition: all 0.2s;
  text-align: center;
}
.showViewer {
  top: 0;
}
.image-viewer img {
  max-height: 85vh;
  width: 100%;
}
.close-btn {
  position: absolute;
  top: 4%;
  right: 4%;
  font-size: 26px;
  color: white;
  cursor: pointer;
}
.mb-15 {
  margin-bottom: 15px;
}

.product-card {
  background-color: white;
  padding: 26px 20px;
  text-align: center;
  box-shadow: 1px 1px 20px #1111112a;
  /* height: 525px; */
}
.product-card-related {
  background-color: white;
  padding: 20px 12px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 5px;
  height: 370px;
}
.product-card-related .margin-auto {
  width: 38%;
  object-fit: contain;
}
.product-card-related h5 {
  font-size: 17px;
}
.product-card-related h6 {
  font-size: 14px;
}
.mt-60 {
  margin-top: 60px;
}
.product-card-related p {
  font-size: 14px;
}
.bd-set-width {
  --bs-modal-width: 1050px !important;
}
.bd-para p {
  margin-bottom: 0px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-40 {
  margin-top: 40px;
}
.variant-price {
  margin-top: 10px;
}
.related-modal {
  text-align: center;
}
.related-modal img {
  width: 100%;
}
.margin-auto {
  margin: auto;
}
.bd-header-modal h4 {
  font-size: 1.5rem;
  color: #197a45 !important;
  font-weight: 600;
  margin-bottom: 0px;
}
.btn-closed-green {
  background-color: transparent;
  border: none;
  font-size: 21px;
  margin-bottom: 15px;
}
.font-26 {
  font-size: 28px;
}
.from-control-select {
  padding: 8px;
  background-color: white;
  border: 1px solid;
  text-align: left;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 5px;
}
.mt-15 {
  margin-top: 15px;
}
.bd-cart-length {
  font-size: 7px;
  position: absolute;
  top: -6px;
  right: 82px;
  background-color: white !important;
  color: #197a45;
  --bs-badge-padding-x: 0.45em;
  --bs-badge-padding-y: 0.2em;
}
.bd-back-login {
  background-color: rgba(12, 77, 41, 0.78);
}
.mr-10 {
  margin-right: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.swal-text {
  text-align: center;
}
.text-right {
  text-align: right;
  line-height: 1;
  height: 50px;
}
.bd-back-login .modal-dialog {
  max-width: 400px !important;
}
.bd-back-login .modal-content {
  padding: 10px 5px;
}
.pr-10 {
  padding-right: 10px;
}
.font-20 {
  font-size: 20px;
}
.no-spacing p {
  margin-bottom: 0px;
}
.bd-logos {
  margin-bottom: 10px;
}
.bd-btn-cart-detail {
  /* position: absolute; */
  bottom: 12px;
  /* width: 86%; */
}
.show-more {
  font-size: 14px;
  color: #0c4d29;
  font-weight: 600;
  cursor: pointer;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.active-ingredien {
  text-align: center;
  display: flex;
  justify-content: center;
}

.active-ingredient table td {
  width: 0 !important;
  margin-right: 0px !important;
}
.active-ingredient table {
  width: 100% !important;
  margin-right: 0px !important;
}
.active-ingredient p {
  height: 0px;
}
.h-105{
  height: 105% !important;
}
@media screen and (min-width: 2401px) and (max-width: 2700px) {
  .big-width {
    width: 20%;
  }
  .product-card > img{
    /* height: 300px; */
  }
  /* .product-card{
    height: 555px;
  } */
  .bd-set-width{
    --bs-modal-width: 1300px !important;
  }
}
@media screen and (min-width: 2701px) and (max-width: 4500px) {
  .big-width {
    width: 16.65%;
  }
  .turf-card {
    height: 530px;
  }
  /* .product-card {
    height: 550px;
  } */
  .product-card-related {
    height: 360px;
  }
  .select-field1 {
    width: 200px;
  }
  .select-field2 {
    width: 200px;
  }
  .select-field {
    width: 200px;
  }
  .main-img img{
    width: 100%;
    height:auto;
  }
  .bd-set-width{
    --bs-modal-width: 1300px !important;
  }
}
.w-40 {
  width: 40%;
}
.slick-prev {
  left: -29px !important;
  z-index: 9999;
}
.slick-arrow::before {
  color: #197a45 !important;
  font-size: 34px !important;
  opacity: 1 !important;
}

.slick-next {
  z-index: 9999;
  right: -21px !important;
}
