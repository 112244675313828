.container-box{
    width: 94%;
    margin: auto;
}

/* text colors */
.text-light-gray{
    color: #787878 !important;
}
.text-green{
    color: #0c4d29 !important;
}

/* font weights */
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}

.h5{
    text-transform: uppercase;
    color: #5A6469;
    letter-spacing: 0.4px;
}
.paragraph{
    color: #5A6469;
    line-height: 30px;
    font-size: 16px;
    letter-spacing: 0.5px;
}

/* button */
.btn-green{
    padding: 10px 20px;
    background-color: #197a45;
    border: none;
    color: white;
    text-decoration: none;
    margin: 10px 0;
    display: inline-block;
    border-radius: 2px;
}
.btn-green:hover{
    background-color: #0c4d29 ;
}